// NOTES:
// - You can include up to 50 blocks in each message, and 100 blocks in modals or home tabs.

import { SlackSurvey } from 'src/models';

export const TEXT_TYPES = {
  PLAIN_TEXT: 'plain_text',
  MARKDOWN: 'mrkdwn',
};

export const ELEMENT_TYPES = {
  IMAGE: 'image',
};

export const BLOCK_TYPES = {
  SECTION: 'section',
  IMAGE: 'image',
  DIVIDER: 'divider',
  HEADER: 'header',
  CONTEXT: 'context',
  BUTTONS: 'actions',
  SURVEY: 'survey',
  RICH_TEXT: 'rich_text',
};

export const BLOCK_NAMES = {
  [BLOCK_TYPES.SECTION]: 'Section',
  [BLOCK_TYPES.IMAGE]: 'Image',
  [BLOCK_TYPES.DIVIDER]: 'Divider',
  [BLOCK_TYPES.HEADER]: 'Header',
  [BLOCK_TYPES.CONTEXT]: 'Context',
  [BLOCK_TYPES.BUTTONS]: 'Buttons',
  [BLOCK_TYPES.SURVEY]: 'Survey',
  [BLOCK_TYPES.RICH_TEXT]: 'Rich Text',
};

export type BlockType =
  | typeof BLOCK_TYPES.SECTION
  | typeof BLOCK_TYPES.IMAGE
  | typeof BLOCK_TYPES.DIVIDER
  | typeof BLOCK_TYPES.HEADER
  | typeof BLOCK_TYPES.CONTEXT
  | typeof BLOCK_TYPES.BUTTONS
  | typeof BLOCK_TYPES.SURVEY;

interface PlainTextObject {
  type: typeof TEXT_TYPES.PLAIN_TEXT;
  text: string;
  emoji?: boolean; // Escapes emojis to colon format.
}

interface MarkdownObject {
  type: typeof TEXT_TYPES.MARKDOWN;
  text: string;
  verbatim?: boolean; // Auto converts links, names, etc if false. Defaults to false.
}

type TextObject = PlainTextObject | MarkdownObject;

interface ImageElement {
  type: 'image';
  image_url: string;
  alt_text: string;
}

interface ButtonElement {
  action_id?: string;
  type: 'button';
  text: {
    type: string;
    text: string;
    emoji: boolean;
  };
  style?: string;
  value?: string;
  url?: string;
  automationId?: string;
}

type Element = ImageElement;

export interface SectionBlock {
  type: typeof BLOCK_TYPES.SECTION;
  htmlContent: string;
  accessory?: Element;
  block_id?: string;
}

interface ImageBlock {
  type: typeof BLOCK_TYPES.IMAGE;
  image_url: string; // Maximum length for this field is 3000 characters.
  alt_text: string; // Maximum length for this field is 2000 characters
  title?: PlainTextObject; // Maximum length for the text in this field is 2000 characters.
  block_id?: string;
}

interface DividerBlock {
  type: typeof BLOCK_TYPES.DIVIDER;
  block_id?: string;
}

export interface HeaderBlock {
  type: typeof BLOCK_TYPES.HEADER;
  text: PlainTextObject; // Maximum length for the text in this field is 150 characters.
  block_id?: string;
}

interface ContextBlock {
  type: typeof BLOCK_TYPES.CONTEXT;
  elements: (
    | {
        type: 'text';
        htmlContent: string;
      }
    | {
        type: 'image';
        image_url: string;
        alt_text?: string;
      }
  )[];
  block_id?: string;
}

export interface ButtonsBlock {
  type: typeof BLOCK_TYPES.BUTTONS;
  elements: ButtonElement[];
  block_id?: string;
}

export interface SurveyBlock {
  type: typeof BLOCK_TYPES.BUTTONS;
  survey: SlackSurvey;
  block_id?: string;
}

export interface RichTextBlock {
  type: typeof BLOCK_TYPES.RICH_TEXT;
  htmlContent: string;
  block_id?: string;
}

export type Block = (
  | SectionBlock
  | ImageBlock
  | DividerBlock
  | HeaderBlock
  | ContextBlock
  | ButtonsBlock
  | SurveyBlock
  | RichTextBlock
) & { survey_id?: string };

export function isSection(block: Block): block is SectionBlock {
  return block.type === BLOCK_TYPES.SECTION;
}

export function isHeader(block: Block): block is HeaderBlock {
  return block.type === BLOCK_TYPES.HEADER;
}

export function isContext(block: Block): block is ContextBlock {
  return block.type === BLOCK_TYPES.CONTEXT;
}

export function isButtons(block: Block): block is ButtonsBlock {
  return block.type === BLOCK_TYPES.BUTTONS;
}

export function isImage(block: Block): block is ImageElement {
  return block.type === ELEMENT_TYPES.IMAGE;
}

export function isSurvey(block: Block): block is SurveyBlock {
  return block.type === BLOCK_TYPES.SURVEY;
}

export function isRichText(block: Block): block is RichTextBlock {
  return block.type === BLOCK_TYPES.RICH_TEXT;
}

export function isDivider(block: Block): block is DividerBlock {
  return block.type === BLOCK_TYPES.DIVIDER;
}

export interface SendAs {
  type: string;
  id?: string;
}

export function unescapeHtml(escapedText: string): string {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = escapedText;
  return textArea.value;
}

export function htmlToBlocks(html: string): Block[] {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const blocks: Block[] = [];

  doc.body.childNodes.forEach((node) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      // Check if the node is an Element
      const element = node as any; // Cast to Element

      if (element.nodeName === 'H1') {
        blocks.push({
          type: BLOCK_TYPES.HEADER,
          text: { type: 'plain_text', text: unescapeHtml(element.textContent) },
        });
      } else if (element.nodeName === 'ARTICLE') {
        blocks.push({
          type: BLOCK_TYPES.RICH_TEXT,
          htmlContent: element.innerHTML,
        });
      } else if (element.nodeName === 'IMG') {
        blocks.push({
          type: BLOCK_TYPES.IMAGE,
          image_url: unescapeHtml(element.getAttribute('src')),
          alt_text: unescapeHtml(element.getAttribute('alt')),
        });
      } else if (element.nodeName === 'HR') {
        blocks.push({ type: BLOCK_TYPES.DIVIDER });
      } else if (element.nodeName === 'SECTION') {
        // Check if it's a survey section
        if (element.hasAttribute('data-type') && element.getAttribute('data-type') === 'survey') {
          const surveyId = element.getAttribute('data-survey-id');
          const surveyButtonLabel = element.getAttribute('data-survey-button-label');
          const surveyButtonLayout = element.getAttribute('data-survey-button-layout');
          const surveyType = element.getAttribute('data-survey-type');
          const surveyPostSubmissionMessage = element.getAttribute('data-survey-post-submission-message');
          const surveySelectionOptions = element.getAttribute('data-survey-selection-options');

          blocks.push({
            type: BLOCK_TYPES.SURVEY,
            survey: {
              id: surveyId,
              buttonLabel: surveyButtonLabel,
              buttonLayout: surveyButtonLayout as 'inline' | 'single',
              type: surveyType as 'nps' | 'csat',
              postSubmissionMessage: surveyPostSubmissionMessage,
              selectionOptions: surveySelectionOptions,
            },
          });
        } else {
          const spans = Array.from(element.children);
          let content = '';
          let accessory;

          if (spans.length > 1) {
            content = (spans[0] as any).innerHTML;
            accessory = {
              type: 'image',
              image_url: unescapeHtml((spans[1] as any).children[0].getAttribute('src')),
              alt_text: unescapeHtml((spans[1] as any).children[0].getAttribute('alt')),
            };
          } else {
            content = element.innerHTML;
          }

          blocks.push({
            type: BLOCK_TYPES.SECTION,
            htmlContent: content,
            accessory: accessory,
          });
        }
      } else if (element.nodeName === 'FORM') {
        const buttons = Array.from(element.querySelectorAll('a')).map((a: any) => {
          const button = {
            type: 'button',
            text: {
              type: 'plain_text',
              text: unescapeHtml(a.textContent),
              emoji: true,
            },
            style: unescapeHtml(a.getAttribute('data-slack-style')),
          };

          const automationId = a.getAttribute('data-automation-id');
          const href = a.getAttribute('href');

          if (automationId) {
            button['automationId'] = automationId;
          } else if (href) {
            button['url'] = unescapeHtml(href);
          }

          return button;
        });
        blocks.push({
          type: BLOCK_TYPES.BUTTONS,
          elements: buttons as any[],
        });
      }
    } else if (node.nodeType === Node.TEXT_NODE) {
      // Check if the node is a Text node
      const textContent = node.textContent.trim();

      if (textContent) {
        blocks.push({
          type: BLOCK_TYPES.RICH_TEXT,
          htmlContent: textContent, // Add as a rich text block
        });
      }
    }
    // Add more cases as needed for other block types
  });

  return blocks;
}

export function getInlineSurveyButtonElements(survey: any) {
  const type = survey.type;

  const csatOptions =
    survey.selectionOptions === 'emojiFaces' ? ['🤩', '🙂', '😐', '😞', '😡'] : ['1️⃣', '2️⃣', '3️⃣', '4️⃣', '5️⃣'];

  const buttons = [
    {
      type: 'button',
      text: {
        type: 'plain_text',
        text: csatOptions[0],
        emoji: true,
      },
    },
    {
      type: 'button',
      text: {
        type: 'plain_text',
        text: csatOptions[1],
        emoji: true,
      },
    },
    {
      type: 'button',
      text: {
        type: 'plain_text',
        text: csatOptions[2],
        emoji: true,
      },
    },
    {
      type: 'button',
      text: {
        type: 'plain_text',
        text: csatOptions[3],
        emoji: true,
      },
    },
    {
      type: 'button',
      text: {
        type: 'plain_text',
        text: csatOptions[4],
        emoji: true,
      },
    },
  ];

  if (type === 'nps') {
    buttons.unshift({
      type: 'button',
      text: {
        type: 'plain_text',
        text: '0️⃣',
        emoji: true,
      },
    });

    buttons.push(
      {
        type: 'button',
        text: {
          type: 'plain_text',
          text: '6️⃣',
          emoji: true,
        },
      },
      {
        type: 'button',
        text: {
          type: 'plain_text',
          text: '7️⃣',
          emoji: true,
        },
      },
      {
        type: 'button',
        text: {
          type: 'plain_text',
          text: '8️⃣',
          emoji: true,
        },
      },
      {
        type: 'button',
        text: {
          type: 'plain_text',
          text: '9️⃣',
          emoji: true,
        },
      },
      {
        type: 'button',
        text: {
          type: 'plain_text',
          text: '🔟',
          emoji: true,
        },
      },
    );
  }

  return buttons;
}

export function escapeHtml(text: string = ''): string {
  return text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
    .replace(/\n/g, '<br>')
    .replace(/\r/g, '<br>')
    .replace(/\t/g, '    ');
}

export function renderBlocks(blocks: any) {
  return blocks.reduce((acc, next) => {
    if (isRichText(next)) {
      acc += `<article class="slack-block slack-rich-text">${next.htmlContent}</article>`;
    } else if (isHeader(next)) {
      acc += `<h1 class="slack-block slack-header">${escapeHtml(next.text?.text ?? '')}</h1>`;
    } else if (isImage(next)) {
      acc += `<img src="${escapeHtml(next.image_url)}" alt="${escapeHtml(next.alt_text ?? '')}" />`;
    } else if (isDivider(next)) {
      acc += '<hr class="slack-block slack-divider" />';
    } else if (isContext(next)) {
      acc += `<small class="slack-block slack-context">${(next as any).elements.reduce((acc2, next2) => {
        if (next2.type === 'text') {
          acc2 += `<span>${next2.htmlContent}</span>`;
        } else if (next2.type === 'image') {
          acc2 += `<img src="${escapeHtml(next2.image_url)}" alt="${escapeHtml(next2.alt_text ?? '')}" />`;
        }

        return acc2;
      }, '')}</small>`;
    } else if (isSection(next)) {
      acc += `<section class="slack-block slack-section"><span class="slack-section-content">${(next as any).htmlContent}</span>${(next as any).accessory ? `<span class="slack-section-accessory"><img src="${escapeHtml((next as any).accessory.image_url)}" alt="${escapeHtml((next as any).accessory.alt_text ?? '')}" /></span>` : ''}</section>`;
    } else if (isButtons(next)) {
      acc += `<form class="slack-block slack-actions"><ul>${(next as any).elements.reduce((acc2, next2) => {
        if (next2.type === 'button') {
          if (next2.automationId) {
            acc2 += `<li><a class="slack-button" data-automation-id="${escapeHtml(next2.automationId)}" data-slack-style="${escapeHtml(next2.style) ?? 'default'}">${escapeHtml(next2.text.text)}</a></li>`;
          } else if (next2.url) {
            acc2 += `<li><a class="slack-button" href="${escapeHtml(next2.url)}" data-slack-style="${escapeHtml(next2.style) ?? 'default'}">${escapeHtml(next2.text.text)}</a></li>`;
          }
        }

        return acc2;
      }, '')}</ul></form>`;
    } else if (isSurvey(next)) {
      const survey = (next as any).survey;
      const surveyId = (next as any).survey_id || survey?.id;

      acc += `<section data-type="survey" ${surveyId ? `data-survey-id="${surveyId}"` : ''} ${survey.buttonLabel ? `data-survey-button-label="${survey.buttonLabel}"` : ''} ${survey.buttonLayout ? `data-survey-button-layout="${survey.buttonLayout}"` : ''} ${survey.type ? `data-survey-type="${survey.type}"` : ''} ${survey.postSubmissionMessage ? `data-survey-post-submission-message="${survey.postSubmissionMessage}"` : ''} ${survey.selectionOptions ? `data-survey-selection-options="${survey.selectionOptions}"` : ''}>${renderBlocks(
        [
          {
            type: 'actions',
            elements:
              survey.buttonLayout === 'inline'
                ? getInlineSurveyButtonElements(survey as any)
                : [
                    {
                      type: 'button',
                      text: {
                        type: 'plain_text',
                        text: survey.buttonLabel || 'Fill out survey',
                        emoji: true,
                      },
                    },
                  ],
          },
        ],
      )}</section>`;
    }

    return acc;
  }, '');
}
